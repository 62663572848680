import React from 'react';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import parse from 'html-react-parser';

import { Fragment } from 'react'

import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon } from '@heroicons/react/24/outline'

import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileWord, faFileExcel, faFileCsv, faFileText, faCircleCheck, faCode } from '@fortawesome/free-solid-svg-icons'
import toast, { Toaster } from 'react-hot-toast';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const GenerateFiles = ({ text, outputMap, fileName, generationType, goBack }) => {
  const file = fileName.split('.')[0];

  const saveFile = (data, fileName, mimeType) => {
    const blob = new Blob([data], { type: mimeType });
    saveAs(blob, fileName);
  };

  

  const handleGeneratePdf = async () => {
    const isTesting = false;
    let serverLink = isTesting ? 'http://localhost:3000/pdf' : 'https://5.75.152.30/pdf';

    axios.post(serverLink, {
      html: text,
    },
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        }
      }
    ).then((response) => {
      saveFile(response.data, file + ' - ocerizare.ro.pdf', 'application/pdf');
    }).catch((error) => {
    });
  };


  const getPlainString = (html) => {
    var plainString = text.replace(/<\/[^>]+><[^>]+>/g, '\n');
    plainString = plainString.replace(/<[^>]+>/g, '');
    return plainString;
  }

  const handleGenerateTxt = () => {
    saveFile(getPlainString(text), file + ' - ocerizare.ro.txt', 'text/plain');
  };

  const handleGenerateDocx = () => {
    const isTesting = false;
    let serverLink = isTesting ? 'http://localhost:3000/docx' : 'https://5.75.152.30/docx';

    axios.post(serverLink, {
      html: text,
    },
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        }
      }
    ).then((response) => {
      saveFile(response.data, file + ' - ocerizare.ro.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
    }).catch((error) => {
    });
  }

  const handleGenerateJson = () => {
    const json = JSON.stringify(outputMap, null, 2);
    saveFile(json, file + ' - ocerizare.ro.json', 'application/json');
  };

  const handleGenerateExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet([outputMap]);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, file + ' - ocerizare.ro.xlsx');
  };

  const handleGenerateCsv = () => {
    var keys = Object.keys(outputMap);
    var header = keys.join(',');
    var data = keys.map(function (v) { return '"' + outputMap[v] + '"'; }).join(',');
    // saveFile(header + '\n' + data, 'generated.csv', 'text/csv;encoding:ISO-8859-2');
    saveFile(header + '\n' + data, file + ' - ocerizare.ro.csv', 'text/csv;encoding:utf-8');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getPlainString(text));

    const textArea = document.createElement("textarea");
    textArea.value = getPlainString(text);
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    toast('Copied to clipboard!', {
      style: {},
      icon: <FontAwesomeIcon className='w-6 h-6 text-green-400' icon={faCircleCheck} />
    });
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    }}>

      <Toaster
        position="top-center"
        reverseOrder={false}
      />

      <div className='w-full flex flex-col md:flex-row'>
        <div className='w-full md:w-4/5 h-full'>
          {generationType !== 'clasic' && <div className='w-full' style={{ maxHeight: '400px', overflowY: 'auto' }}> {Table(outputMap)} </div>}
          {generationType === 'clasic' && <div className={'w-full m-auto p-5 border border-gray-200 rounded'} style={{ height: '400px', overflowY: 'auto', textAlign: 'left' }}> {parse(text)} </div>}
        </div>

        <div className='w-full md:w-2/5 mt-7 md:mt-0 h-full flex flex-col justify-center items-center px-3'>
          <div className='' style={{ width: "90%" }}>
            <div className='flex justify-center items-center mb-5'>
              <div className="mr-2">
                <CheckCircleIcon className="h-8 w-8 text-green-400" aria-hidden="true" />
              </div>
              <p className='font-bold'>Done!</p>
            </div>

            <div className='mb-5'>
              <p className='font-bold'>Your file is ready</p>
              <p className='text-gray-500 text-sm'>{fileName}</p>
            </div>

            <div className='flex flex-col space-y-3 mb-5'>
              {CopyComponent(generationType, handleCopy)}
              {MenuComponent(generationType, handleGeneratePdf, handleGenerateDocx, handleGenerateTxt, handleGenerateExcel, handleGenerateCsv, handleGenerateJson)}
            </div>

            <button onClick={goBack} className='underline text-gray-500'>Start over</button>
          </div>
        </div>
      </div>

      {/* <div style={
        {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }
      }>
        {CopyComponent(generationType, handleCopy)}
        {MenuComponent(generationType, handleGeneratePdf, handleGenerateDocx, handleGenerateTxt, handleGenerateExcel, handleGenerateCsv, handleGenerateJson)}
      </div> */}
    </div >
  );
};

export default GenerateFiles;

function CopyComponent(generationType, handleCopy) {
  return (<>
    {generationType === 'clasic' &&
      <button
        onClick={handleCopy}
        // className="flex justify-center items-center px-6 py-2.5 bg-indigo-600 hover:bg-indigo-500 text-white text-sm font-semibold rounded-md shadow-sm ring-1 ring-inset ring-gray-200"
        className="flex justify-center items-center px-6 py-2.5 bg-white text-gray-900 text-sm font-semibold rounded-md shadow-sm ring-1 ring-inset ring-gray-200"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-indigo-500">
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
        </svg>
        <span className="ml-2">Copy to Clipboard</span>
      </button>
    }
  </>);
}

function MenuComponent(generationType, handleGeneratePdf, handleGenerateDocx, handleGenerateTxt, handleGenerateExcel, handleGenerateCsv, handleGenerateJson) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        {/* <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-6 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"> */}
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-indigo-600 hover:bg-indigo-500 px-6 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-200">
          Download
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {/* <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"> */}
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {generationType === 'clasic' &&
              <>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleGeneratePdf}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'px-4 py-2 text-sm w-full text-left flex items-center'
                      )}
                    >
                      <FontAwesomeIcon className='w-6 h-6' icon={faFilePdf} />
                      <span className="ml-3">Download as PDF</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleGenerateDocx}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'px-4 py-2 text-sm w-full text-left flex items-center'
                      )}
                    >
                      <FontAwesomeIcon className='w-6 h-6' icon={faFileWord} />
                      <span className="ml-3">Download as Word</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleGenerateTxt}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'px-4 py-2 text-sm w-full text-left flex items-center'
                      )}
                    >
                      <FontAwesomeIcon className='w-6 h-6' icon={faFileText} />
                      <span className="ml-3">Download as .txt</span>
                    </button>
                  )}
                </Menu.Item>
              </>
            }
            {(generationType === 'smart' || generationType === 'custom') &&
              <>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleGenerateExcel}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'px-4 py-2 text-sm w-full text-left flex items-center'
                      )}
                    >
                      <FontAwesomeIcon className='w-6 h-6' icon={faFileExcel} />
                      <span className="ml-3">Download as Excel</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleGenerateCsv}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'px-4 py-2 text-sm w-full text-left flex items-center'
                      )}
                    >
                      <FontAwesomeIcon className='w-6 h-6' icon={faFileCsv} />
                      <span className="ml-3">Download as CSV</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleGenerateJson}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'px-4 py-2 text-sm w-full text-left flex items-center'
                      )}
                    >
                      <FontAwesomeIcon className='w-6 h-6' icon={faCode} />
                      <span className="ml-3">Download as JSON</span>
                    </button>
                  )}
                </Menu.Item>
              </>
            }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function Table(tableData) {

  return (
    <div>
      <section className="mt-auto">
        {/* <h2 className="text-lg font-medium text-gray-800 ">Customers</h2>
        <p className="mt-1 text-sm text-gray-500 ">These companies have purchased in the last 12 months.</p> */}
        <div className="flex flex-col">
          {/* <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"> */}
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border border-gray-200 ">
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 ">
                  <tr>
                    <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
                      {/* <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 border-r"> */}
                      Key
                    </th>

                    <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.keys(tableData).map((key) => (
                    // {tableData.map((item, index) => (
                    <tr key={key}>
                      <td className="px-4 py-4 text-sm font-medium text-left">
                        {/* <td className="px-4 py-4 text-sm font-medium text-left border-r"> */}
                        <div>
                          <h2 className="font-medium text-gray-800">{key}</h2>
                          {/* <p className="text-sm font-normal text-gray-600">catalogapp.io</p> */}
                        </div>
                      </td>
                      <td className="px-12 py-4 text-sm text-left">
                        <div className="text-sm font-normal rounded-full text-gray-500">
                          {/* {tableData[key]} */}
                          <DisplayData data={tableData[key]} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
    </div>
  );
}

function DisplayData({ data }) {
  let output;

  if (typeof data === 'object' && data !== null) {
    output = JSON.stringify(data, null, 2);  // pretty-print the JSON
    return (<div><pre>{output}</pre></div>);
  } else {
    output = data;  // data is already a string
    return (<div>{output}</div>);
  }
}
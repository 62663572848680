import React from 'react';
import loadingGif from './assets/loading3.gif';  // Adjust the path if necessary


const LoadingScreen = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <img src={loadingGif} alt="Loading..." />
        </div>
    );
};

export default LoadingScreen;
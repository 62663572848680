import './App.css';
import DragAndDropFileUpload from './components/DragDrop';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

const NotFound = () => {
  return (
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center">
        <p class="text-base font-semibold text-indigo-600">404</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
        <p class="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn't find the page you're looking for.</p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a href="/" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Go back home</a>
        </div>
      </div>
    </main>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          // height: "100vh",
        }}>
          <Routes>
            <Route path="/" element={<DragAndDropFileUpload key="clasic" generationType="clasic"/>}/>
            <Route path="/classic" element={<DragAndDropFileUpload key="clasic" generationType="clasic"/>}/>
            <Route path="/smart" element={<DragAndDropFileUpload key="smart" generationType="smart"/>}/>
            <Route path="/custom" element={<DragAndDropFileUpload key="custom" generationType="custom"/>}/>
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </div>
      </div>
    </Router >
  );
}

export default App;
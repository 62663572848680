

import React, { useState, useRef, useEffect } from 'react';
import GenerateFiles from './GenerateFiles';

import { PhotoIcon } from '@heroicons/react/24/solid'
import LoadingScreen from './LoadingScreen'; // adjust the path
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { Transition, Dialog } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import { Fragment } from 'react'
import { pdfjs } from 'react-pdf';

import './assets/style.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const DragAndDropFileUpload = (props) => {
  const generationType = props.generationType;

  const [lastFileName, setLastFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [pages, setPages] = useState(1);

  // clasic
  const [finalText, setFinalText] = useState("");

  // smart + custom
  // eslint-disable-next-line no-unused-vars
  const [inputMap, setInputMap] = useState([]);
  const [outputMap, setOutputMap] = useState({});

  const formRef = useRef(null);
  const [inputs, setInputs] = useState([{ id: 0, value: '' }]);
  const [isInput, setIsInput] = useState(false);

  const [isGenerated, setIsGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [isError, setIsError] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [isPdfPageLimitReached, setIsPdfPageLimitReached] = useState(false);

  

  const handleInputChange = (e, id) => {
    const updatedInputs = inputs.map((input) =>
      input.id === id ? { ...input, value: e.target.value } : input
    );
    setInputs(updatedInputs);
    
    
  };

  const handleAddInput = () => {
    formRef.current && formRef.current.reportValidity();

    // Only add a new input if the last one is not empty
    if (inputs[inputs.length - 1].value !== '') {
      const newInputId = inputs[inputs.length - 1].id + 1;
      setInputs([...inputs, { id: newInputId, value: '' }]);
    }

    
  };

  const handleRemoveInput = (id) => {
    if (inputs.length === 1) {
      return; // Prevent removing the last input
    }
    const updatedInputs = inputs.filter((input) => input.id !== id);
    setInputs(updatedInputs);

    
  };

  useEffect(() => {
    if (inputs[0]['value'] === '') {
      setIsInput(false);
    } else {
      setIsInput(true);
    }
  }, [inputs]);

  const handleDragOver = (e) => {
    
    e.preventDefault();
  };

  const handleDrop = (e) => {
    
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    setSelectedFile(file);

    const isPdf = checkPdf(file);
    if (isPdf) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        const pdf = await pdfjs.getDocument(arrayBuffer).promise;
        const pageCount = pdf.numPages;
        setPages(pageCount);
      };
    } else {
      setPages(1);
    }
  };

  const handleFileInputChange = (e) => {
    
    const file = e.target.files[0];
    setSelectedFile(file);

    const isPdf = checkPdf(file);
    if (isPdf) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        const pdf = await pdfjs.getDocument(arrayBuffer).promise;
        const pageCount = pdf.numPages;
        setPages(pageCount);
      };
    } else {
      setPages(1);
    }
  };


  const checkPdf = (file) => {
    return file.name.split('.').pop() === 'pdf'
  }

  const checkFileAndUpload = () => {
    
    const isPdf = checkPdf(selectedFile);

    // get the number of pages in pdf
    if (isPdf) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        const pdf = await pdfjs.getDocument(arrayBuffer).promise;
        const pageCount = pdf.numPages;

        if (pageCount <= 5) {
          upload(true);
        } else {
          setIsPdfPageLimitReached(true);
          return;
        }
      };
    } else {
      upload(false);
    }
  }

  const upload = (isPdf) => {
    const formData = new FormData();

    if (isLoading) {
      return;
    }

    if (generationType === 'custom') {
      const list = inputs.map((input) => input.value);

      // remove all empty inputs
      list.filter((item) => item !== '');
      list.filter((item) => item !== ' ');

      if (list.length === 0 || list[0] === '') {
        // alert("Please add at least one input");
        return;
      }

      setInputMap(list);
      let listString = list.join(',');
      formData.append('arrList', listString);
    }

    formData.append('image', selectedFile);
    formData.append('type', generationType);

    const isTesting = false;
    let serverLink = isTesting ? 'http://localhost:3000' : 'https://5.75.152.30';

    let uploads = localStorage.getItem('uploads');

    let date = localStorage.getItem('date');
    if (!uploads || uploads === "NaN" || uploads === "undefined") {
      localStorage.setItem('uploads', "0");
      uploads = "0";
      date = new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear()
    }

    // formData.append('uploads', isTesting ? 1 : uploads);
    // formData.append('uploads', 1);
    formData.append('uploads', uploads);
    formData.append('date', date);

    setIsLoading(true);

    const controller = new AbortController();
    setAbortController(controller);

    fetch(serverLink + '/upload' + (isPdf ? "Pdf" : ''), {
      method: 'POST',
      body: formData,
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
      signal: controller.signal,
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'error' || data.status === 'cookie_error' || data.status === 'limit_reached') {
          setIsLoading(false);
          if (data.status === 'limit_reached') {
            setIsLimitReached(true);
          } else {
            setIsError(true);
          }
          
          // goBack();
          return;
        }

        if (generationType === 'clasic') {
          setFinalText(data.text.htmlFormatedText);
        } else if (generationType === 'smart' || generationType === 'custom') {
          setOutputMap(data.result);
        }

        let uploads = data.uploads;
        localStorage.setItem('uploads', uploads);
        localStorage.setItem('date', data.date);

        setIsGenerated(true);
        setLastFileName(selectedFile.name);
        setSelectedFile(null);
        setIsLoading(false);
        setAbortController(null);
      })
      .catch(error => {
        // console.error(error);
        setAbortController(null);
        setIsLoading(false);
        setIsError(true);
        // goBack();
      })
  }

  const goBack = () => {
    if (abortController) {
      abortController.abort();
    }

    setIsGenerated(false);
    setSelectedFile(null);

    setIsLoading(false);
    // setIsError(false);
    setIsLimitReached(false);
    setIsPdfPageLimitReached(false);

    setFinalText("");

    setInputMap([]);
    setOutputMap({});
    setInputs([{ id: 0, value: '' }]);

    setLastFileName("");
  }

  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
    }}>
      {/* <div className="flex items-start justify-center">
        <nav>
          <ul className="flex space-x-4">
            <li><Link className="text-blue-500 hover:underline" to="/clasic">clasic</Link></li>
            <li><Link className="text-blue-500 hover:underline" to="/smart">smart</Link></li>
            <li><Link className="text-blue-500 hover:underline" to="/custom">custom</Link></li>
          </ul>
        </nav>
      </div>

      {generationType}
      <br></br>
      <br></br> */}

      {isError && <UnknownErrorDialog open={isError} goBack={goBack} retry={checkFileAndUpload} setOpen={setIsError} />}
      {isLimitReached && <LimitReachedDialog open={isLimitReached} setOpen={setIsLimitReached} />}
      {isPdfPageLimitReached && <PdfPageLimitDialog open={isPdfPageLimitReached} setOpen={setIsPdfPageLimitReached} />}

      {/* go back button */}
      {(isGenerated || isLoading || (selectedFile && generationType === 'custom')) &&
        <button
          onClick={goBack}
          className="inline-flex items-center px-6 py-2.5 mb-3 bg-indigo-600 hover:bg-indigo-500 text-white text-sm font-semibold rounded-md shadow-sm ring-1 ring-inset ring-indigo-300"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd" />
          </svg>

          <span className="ml-2">Go back</span>
        </button>
      }

      {/* dropdown zone */}
      {(!isGenerated && !isLoading) &&
        <div className="w-full flex flex-col md:flex-row" style={{
          // height: '400px',
          // overflowY: 'auto',
        }}>
          {/* left zone (dropdown) */}
          <div
            className={`${(selectedFile && generationType === 'custom') ? 'w-full md:w-3/5' : 'w-full'} h-300 md:h-400 flex flex-col justify-center items-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6`}
            style={{ backgroundColor: '#F1F3F5' }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {!selectedFile &&
              <div className="text-center">
                <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                <div className="mt-4 flex items-center text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="px-2 py-0.5 relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFileInputChange} />
                  </label>
                  <p className="">or drag and drop</p>
                </div>
                <p className="mt-1 text-xs leading-5 text-gray-600">PNG, JPG or PDF up to 5 Pages</p>

                {selectedFile && <p className='text-indigo-600'>{selectedFile.name}</p>}
              </div>}

            {selectedFile &&
              <div className='' style={{ width: generationType === 'custom' ? '170px' : '170px', borderRadius: '10px' }}>
                <div className='flex flex-col border rounded-lg'>
                  <div className='bg-white p-1.5 rounded-t-lg'>
                    <div className='bg-gray-100 py-10 rounded-lg'>
                      <PhotoIcon className="mx-auto h-8 w-8 text-gray-400" aria-hidden="true" />
                    </div>
                  </div>
                  <div className='bg-white px-4 rounded-b-lg'>
                    <div className="mb-3 mt-1">
                      <p className="text-sm font-semibold text-gray-800 line-clamp-2">
                        {selectedFile.name}
                      </p>
                    </div>
                    <div className="mb-1">
                      <p className="text-xs text-gray-400">
                        {pages === 1 ? pages + " page" : pages + " pages"}
                      </p>
                    </div>
                    <button onClick={goBack} className="flex mb-2 text-gray-500 hover:text-gray-600 transition-all duration-300">
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              </div>}
          </div>
          {/* end left zone */}

          {/* right zone (custom inputs) */}
          {selectedFile && generationType === 'custom' &&
            <div className='w-full md:w-2/5 h-300 md:h-400 mt-5 md:mt-0 md:ml-5 '>
              <div className='h-full w-full flex flex-col items-center overflow-y-auto'>
                {/* <div className="flex-grow" /> Spacer */}
                <div style={{ width: '70%' }}>
                  {/* title for the inptus */}
                  <div className='mb-5'>
                    <p className="block text-base font-medium text-left text-gray-900">
                      Input fields
                    </p>
                    <p className="block text-sm text-left text-gray-500">
                      Here you can add the keys that will be used to generate the output.
                    </p>
                  </div>

                  <form ref={formRef}>
                    {inputs.map((input, index) => (
                      <div key={input.id} className='flex mb-4 items-center'>
                        <div className="relative w-full">
                          <label
                            htmlFor="name"
                            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                          >
                            Input {index + 1}
                          </label>
                          <input
                            onChange={(e) => handleInputChange(e, input.id)}
                            type="text"
                            name="name"
                            id={input.id}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="extracted value"
                            required
                          />
                        </div>

                        {inputs.length > 1 && (
                          <button
                            onClick={() => handleRemoveInput(input.id)}
                            className="bg-red-400 hover:bg-red-500 w-4 h-4 flex items-center justify-center rounded-full ml-3 p-1"
                            aria-label="Remove"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                            </svg>
                          </button>
                        )}
                      </div>
                    ))}
                    <div className='flex mb-10'>
                      <button
                        onClick={handleAddInput}
                        type="button"
                        className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                      >
                        Add new field
                      </button>
                    </div>
                  </form>
                </div>
                {/* <div className="flex-grow" /> Spacer */}
              </div>
            </div>}
          {/* end right zone */}
        </div>
      }

      {/* loading scrteen */}
      {isLoading && <div className="w-full" style={{
        height: '400px',
        overflowY: 'auto',
        backgroundColor: '#F1F3F5',
      }}>
        <div
          className="h-full flex flex-col justify-center items-center rounded-lg border border-dashed border-gray-900/25 px-6 py-20"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <LoadingScreen />
        </div>
      </div>}

      {/* upload button */}
      {selectedFile &&
        <div className='w-full flex justify-end mt-3'>
          <button
            disabled={isLoading || (selectedFile && generationType === 'custom' && !isInput)}
            onClick={checkFileAndUpload}
            className={`inline-flex items-center px-6 py-2.5 mb-3 text-white text-sm font-semibold rounded-md shadow-sm ring-1 ring-inset ring-indigo-300
              ${(isLoading || (selectedFile && generationType === 'custom' && !isInput)) ? 'bg-gray-400 pointer-events-none' : 'bg-indigo-600 hover:bg-indigo-500'}`}
          >
            <div className="flex items-center justify-center">
              {isLoading && <div className="h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-white border-4 mr-3"></div>}
              {!isLoading && <div>Upload</div>}
              {isLoading && <div>Uploading</div>}
            </div>
          </button>
        </div>}

      {/* result screen */}
      {isGenerated ? (
        <div className='w-full flex flex-col items-start justify-center'>
          <GenerateFiles
            text={finalText}
            outputMap={outputMap}
            generationType={generationType}
            fileName={lastFileName}
            goBack={goBack}
          />
        </div>
      ) : (<></>)}
    </div >
  );
};

function UnknownErrorDialog({ open, retry, goBack, setOpen }) {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Oops!
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          An unknown error occurred. Please try again later.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={() => { setOpen(false); retry(); }}
                  >
                    Retry
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => { setOpen(false); goBack(); }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function LimitReachedDialog({ open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    {/* <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> */}
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Limit Reached!
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        You have reached your free limit of 10 generated pages per day. Contact us for more uploads or wait until tomorrow.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex:row">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function PdfPageLimitDialog({ open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    {/* <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> */}
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Too Many Pages!
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        The uploaded PDF has more than 5 pages. Please upload a file with 5 pages or fewer.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex:row">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default DragAndDropFileUpload;